<template>
    <CInputGroup>
        <CInput class="mt-3 justify-content-center">
            <template #input>
                <logo-input
                    :image-url="avatarUrl"
                    :display-name="displayName"
                    :uploading="uploading || loading"
                    :size="150"
                    :enabled="userId === authUser.id"
                    :classes="{ 'is-invalid': !!!validationErrors.photo }"
                    @input="handleInputLogo($event)"
                />
            </template>
        </CInput>
        <CFormText v-if="validationErrors.photo" style="color: red">{{
            validationErrors.photo
            }}
        </CFormText>
    </CInputGroup>
</template>

<script>
import LogoInput from '@/components/LogoInput'
import {parseError} from '@/utils/api'

export default {
    name: 'CompanyUserProfileAvatarUploader',
    components: {LogoInput},
    inject: ['toast'],
    props: {
        userId: [Number, String],
        avatarUrl: String,
        displayName: {
            type: Array,
            default: () => []
        },
        withoutUpload: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['change'],
    data: () => ({
        validationErrors: {photo: null},
        uploading: false,
        form: {
            photo: null,
        },
    }),
    methods: {
        handleInputLogo(file) {
            this.validationErrors.photo = null

            if (file.target.value && file.target.value.error) {
                this.validationErrors.photo = file.target.value.error
                this.form.photo = null
            } else {
                if (typeof file.target.value === 'object') {
                    this.form.photo = file.target.value
                    this.$emit('change', file.target.value)
                    this.handleSubmit()
                }
            }
        },
        handleSubmit() {
            if (this.withoutUpload) return
            if (!this.userId) return
            this.uploading = true
            const formData = new FormData()
            formData.append('user_id', this.userId)
            formData.append('photo', this.form.photo)
            this.$http.userProfiles
                .uploadAvatar(formData)
                .then((res) => {
                    this.toast('info', res.data.message)
                    if (res.data.photo) {
                        this.$store.commit('setUserAvatar', res.data.photo)
                    }
                })
                .catch((err) => {
                    err = parseError(err)
                    if (err.status === 422) {
                        this.validationErrors = err.validationMessages
                    } else {
                        this.toast('warning', err.message)
                    }
                })
                .finally(() => {
                    this.uploading = false
                })
        },
    },
}
</script>

<style scoped></style>
