<template>
    <div class="w-100">
        <CNInput v-model="passwordSlug" :disabled="true" :aria-disabled="true" label="Password"/>

        <div>
            <slot name="footer-start"></slot>
            <CButton v-if="canChangePassword" class="d-block ml-auto mt-3" variant="outline" color="primary"
                     @click.prevent="openChangePasswordModal">
                Change Password
            </CButton>
            <LoadingButton v-if="canSendResetPasswordLink" :loading="passwordResetLinkSending" type="button"
                           class="btn btn-primary mt-3" @click.prevent="openSendResetLinkModal">
                Reset password
            </LoadingButton>
        </div>

        <CModal :visible="showChangePasswordModal" backdrop="static" @close="closeModal">
            <CModalHeader>
                <CModalTitle>Change your password</CModalTitle>
            </CModalHeader>
            <CModalBody class="pass-change">
                <div>
                    <input class="autofill-prevent" type="text"/>
                    <CNInputPassword label="Old Password" v-model="form.old_password" max-length="30"
                                     :invalid="validator.has('old_password')" @blur="checkOldPassword"
                                     @input="validator.forget('old_password')"/>
                    <CFormText v-if="validator.has('old_password')" class="text-error">
                        {{ validator.get('old_password') }}
                    </CFormText>

                    <CFormText class="link-primary">
                        <a href="#" class="forgot-password-link" @click.prevent="openSendResetLinkModal">
                            Forgot the password
                        </a>
                        <span class="btn-spinner c-loading-button-spinner spinner-border spinner-border-sm"
                              :class="{ 'd-none': !passwordResetLinkSending }"></span>
                    </CFormText>
                </div>

                <div>
                    <CNInputPassword v-model="form.password" max-length="30" label="Password"
                                     :invalid="validator.has('password') || passwordInvalid"
                                     @blur="passwordTouched = true" @input="validator.forget('password')"/>

                    <div class="pass-change__validations">
                        <div v-for="validator in passValidators" :key="validator.title"
                             :class="[`pass-change__validations-item_${ validator.validator ? 'success' : 'failed' }`]"
                             class="pass-change__validations-item">

                            <div class="pass-change__validations-label"
                                 :style="{ 'background': passwordTouched ? '' : 'silver' }">
                                <CIcon name="cilCheckAlt"/>
                            </div>

                            <span :style="{ 'color': passwordTouched ? '' : 'black' }">{{ validator.title }}</span>
                        </div>
                    </div>
                </div>

                <div>
                    <CNInputPassword v-model="form.password_confirmation" max-length="30" label="Confirm the password"
                                     :invalid="validator.has('password_confirmation')"
                                     @blur="handleChangeField('password_confirmation', $event)"
                                     @input="validator.forget('password_confirmation')"/>
                    <CFormText v-if="validator.has('password_confirmation')"
                               :style="`color: ${validator.has('password_confirmation') && 'red'}`">
                        {{ validator.get('password_confirmation') }}
                    </CFormText>
                </div>

            </CModalBody>

            <CModalFooter class="flex align-items-center justify-content-center">
                <CButton class="modal__btn clear" color="link" @click="closeModal">
                    Cancel
                </CButton>
                <LoadingButton class="modal__btn" color="primary" :loading="submitLoading" @click="handleSubmit">
                    Send
                </LoadingButton>
            </CModalFooter>
        </CModal>
        <confirmation-modal ref="confirmSendResetPasswordLinkModal"/>
    </div>
</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import permissions from '@/utils/constants/permissions'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import {parseError} from '@/utils/api'
import LoadingButton from '@/components/LoadingButton.vue'
import CNInput from "@/components/ui/CNInput/CNInput.vue";
import CNInputPassword from "@/components/ui/CNInputPassword/CNInputPassword.vue";

export default {
    name: 'CompanyUserChangePassword',
    components: {CNInputPassword, CNInput, LoadingButton, ConfirmationModal},
    inject: ['toast'],
    props: ['userId', 'userEmail'],
    emits: ['updated'],
    data: () => ({
        showOldPassword: false,
        showNewPassword: false,
        showConfirmationPassword: false,
        showChangePasswordModal: false,
        showPassword: false,
        showForgotPasswordModal: false,
        passwordResetLinkSending: false,
        validator: {},
        submitLoading: false,

        form: {
            old_password: null,
            password: '',
            password_confirmation: null,
        },

        passwordTouched: false,
        passwordSlug: '************'
    }),
    computed: {
        passwordInvalid() {
            if (!this.passwordTouched) return false

            return !this.passValidators[0].validator ||
                !this.passValidators[1].validator ||
                !this.passValidators[2].validator ||
                !this.passValidators[3].validator
        },
        passValidators() {
            return [
                {
                    title: 'Contains lowercase and uppercase letters',
                    validator: /[a-z]/.test(this.form.password) && /[A-Z]/.test(this.form.password)
                },
                {
                    title: 'Contains at least one number',
                    validator: /\d/.test(this.form.password)
                },
                {
                    title: 'Contains at least one symbol',
                    validator: /[!@#$%^&*()_{}\[\]/'`~<>\-+]/.test(this.form.password)
                },
                {
                    title: 'At least 8 characters',
                    validator: this.form.password.length >= 8
                }
            ]
        },
        isAccountOwner() {
            return this.authUser.id === parseInt(this.userId)
        },
        canChangePassword() {
            return (
                this.isAccountOwner &&
                this.authUser.can(permissions.ACCOUNT_CONFIGURATION_EDIT)
            )
        },
        canSendResetPasswordLink() {
            return (
                !this.isAccountOwner &&
                this.authUser.can(permissions.ACCOUNT_CONFIGURATION_EDIT)
            )
        },
    },
    created() {
        this.setRules()
    },
    methods: {
        openChangePasswordModal() {
            if (
                this.$store.getters['user'] &&
                this.$store.getters['user'].id === parseInt(this.$route.params.id)
            ) {
                this.showChangePasswordModal = true
            }
        },
        async openSendResetLinkModal() {
            if (!this.userEmail)
                return this.toast('warning', 'Email has not been provided')

            if (
                await this.$refs.confirmSendResetPasswordLinkModal.confirm({
                    title: '',
                    text: 'Are you sure you want to send a password reset link?',
                })
            ) {
                this.passwordResetLinkSending = true

                this.$http.auth
                    .forgotPassword({email: this.userEmail})
                    .then((res) => {
                        if (res.status === 201) {
                            this.toast('info', 'The reset link has been sent')
                        }
                    })
                    .catch((error) => {
                        let err = parseError(error)
                        this.toast('warning', err.message)
                    })
                    .finally(() => {
                        this.passwordResetLinkSending = false
                    })
            }
        },
        handleSubmit() {
            const validationResult = this.validator.validateAll(this.form)
            if (!validationResult.hasErrors) {
                this.submitLoading = true

                this.$http.userProfiles
                    .changePassword({...this.form, ...{id: this.userId}})
                    .then((res) => {
                        this.toast('info', res.data.message)
                        this.$emit('updated', res.data.password_modified_date)
                        this.closeModal()
                    })
                    .finally(() => {
                        this.submitLoading = false
                    })
            }
        },
        checkOldPassword() {
            if (this.form.old_password) {
                this.$http.userProfiles
                    .checkPassword({password: this.form.old_password, id: this.userId})
                    .then((res) => {
                        let {equals} = res.data
                        if (equals) {
                            this.validator.forget('old_password')
                        } else {
                            this.validator.set('old_password', this.$t('Password mismatch'))
                        }
                    })
            }
        },
        closeModal() {
            this.showChangePasswordModal = false
            this.form = {
                ...this.form,
                ...{
                    old_password: null,
                    password: '',
                    password_confirmation: null,
                },
            }
            this.validator.forgetAll()
            this.passwordTouched = false
        },
        handleChangeField(field, event) {
            this.validator.validate(field, event)
        },
        setRules() {
            this.validator = validator({
                password: [rules.required, rules.password],
                old_password: [rules.required],
                password_confirmation: [
                    rules.required,
                    rules.passwordConfirmation(() => this.form.password),
                ],
            })
        },
    },
}
</script>

<style scoped lang="scss">
.pass-change {
    display: flex;
    flex-direction: column;
    gap: 16px;


    &__validations {
        margin-top: 12px;

        &:has(> &-label_success) {
            color: green;
        }

        &-item {
            display: flex;
            gap: 8px;
            align-items: center;
            font-size: 12px;

            &_success {
                color: #0BBD99;

                & .pass-change__validations-label {
                    background: #0BBD99;
                }
            }

            &_failed {
                color: #FF1F26;

                & .pass-change__validations-label {
                    background: #FF1F26;
                }
            }
        }

        &-label {
            height: 10px;
            width: 10px;
            border-radius: 200px;
            background: silver;
            display: flex;
            align-items: center;
            justify-content: center;

            & svg {
                height: 8px !important;
                width: 8px !important;
                color: white;
            }
        }
    }
}

.forgot-password-link {
    margin-right: 5px;
}

:deep(.modal.modal-static) {
    .modal-dialog {
        transform: none !important;
    }
}

.is-invalid + .password-visibility-toggle {
    margin-right: 1.7rem;
}

.password-visibility-toggle {
    width: 20px;
    object-fit: contain;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
    cursor: pointer;
}
</style>
